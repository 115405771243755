<template>
  <div id="app" class="container">
    <Roles />
  </div>
</template>

<script>
import Roles from './components/Roles.vue'

export default {
  name: 'App',
  components: {
    Roles
  }
}
</script>

<style>
@font-face {
    font-family: Vazir FD UI;
    src: url('/fonts/Vazir-Regular-FD-UI.woff2') format('woff2'),
    url('/fonts/Vazir-Regular-FD-UI.woff') format('woff');
    font-weight: normal;
    font-display:swap;
}

#app {
  font-family: 'Vazir FD UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
  direction: rtl;
}
</style>
