<template>
    <div>
        <div v-if="!started">
            <div class="form-group">
                <label for="roles">هر نقش را در یک خط بنویسید</label>
                <textarea class="form-control text-center" id="roles" rows="9" v-model="roles"></textarea>
            </div>
            <button class="btn btn-primary btn-block" @click="play"> شروع بازی</button>
        </div>

        <div v-else>
            <button class="btn btn-danger" @click="show" v-if="!role && i < roles.length">نمایش نقش {{ i + 1 }}</button>
            <div class="role" v-if="role">
                <small>نقش شما:</small>
                <br>
                <h5>{{ role }}</h5>
                <button class="btn btn-primary mt-5 btn-block" @click="role = null">نفر بعدی</button>
            </div>
        </div>

        <h4 v-if="started && !role && i >= roles.length">خوش بگذره</h4>

    </div>
</template>

<script>
    import { shuffle } from 'lodash'

    export default {
        name: 'Roles',
        data () {
            return {
                roles: '',
                i: 0,
                started: false,
                role: null
            }
        },
        methods: {
            play () {
                this.save()
                this.roles = this.roles.split('\n').filter(r => r.trim().length > 0);
                this.roles = shuffle(this.roles);
                this.started = true
            },
            show () {
                this.role = this.roles[this.i++]
            },
            save(){
                localStorage.setItem('roles', this.roles)
            }
        },
        created () {
            this.roles = localStorage.getItem('roles') || ''
        }
    }
</script>

<style scoped>
    .roles {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
</style>
